<template>
    <div>
        <div class="bg">
            <img :src="data" width="100%">
        </div>

    </div>
</template>

<script>
  import {userQbs, userQrbill} from "@/api/user";

  export default {
    name: "Qr",
    data () {
      return {
        data: ""
      }
    },
    methods: {
      loadQr () {
        const id = this.$route.query.id;
        const sign = this.$route.query.sign;
        if (id && sign) {
          userQbs({
            id, sign
          }).then(data => {
            this.data = data.data;
            console.log(this.data);
          })
        } else {
          userQrbill({}).then(data => {
            this.data = data.data.data;
            console.log(this.data);
          })
        }
      }
    },
    created () {
      this.loadQr();
    }
  }
</script>

<style scoped>
    .bg {
        width: 100%;
        height: 100vh;
        display: block;
    }

    .ma {
        display: block;
        width: 40%;
        position: absolute;
        bottom: 6rem;
        right: 30%;
    }
</style>
